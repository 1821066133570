import React, { useState, useEffect } from "react";
import { BoxWrapper } from "../../../../assets/css/Custom/AcademicStyle";
import avatar from "../../../../assets/images/default.jpg";
import { useTranslation } from "react-i18next";
import NoticeDeanOffice from "./NoticeDeanOffice";
const DeanPDOffice = () => {
  const { t } = useTranslation();
  const ImgLink =
    "https://nitjsr.ac.in/backend/uploads/Faculty/EE114/profile/f9bbcd88-e19e-4dc3-9b0f-0cddbc843bfc.jpg";

  const keshav =
    "https://nitjsr.ac.in/backend/uploads/Faculty/CE108/profile/29432e47-14ee-45e9-acfb-f822cb4a7b8f.jpg";
  const sanjay =
    "https://nitjsr.ac.in/backend/uploads/Faculty/EE106/profile/ca3cba31-e47d-4fc0-adc3-0117a75aaa59.jpg";
  const somenath =
    "https://nitjsr.ac.in/backend/uploads/Faculty/CE119/profile/37de570f-3409-451b-8c0c-57245e0a61c5.jpg";
  return (
    <>
      <div>
        <BoxWrapper>
          <div className="row ">
            <div className="col-sm-12  my-8">
              <div className="thumbnail text-center  my-5">
                <img
                  src={ImgLink}
                  alt="hod"
                  id="hodimg"
                  style={{
                    width: "180px",
                    height: "180px",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <hr />
              <div className="caption content NameArea">
                <h5
                  className="text-center"
                  style={{ fontWeight: "600", color: "#274187" }}
                >
                  {t("d-pnd")}
                </h5>
                <p className="text-center"> {t("n-pnd")}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  flexWrap: "wrap",
                }}
              >
                <div className="thumbnail text-center  my-1 mx-1">
                  <img
                    src={keshav}
                    alt="hod"
                    id="hodimg"
                    style={{
                      width: "180px",
                      height: "180px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="caption content NameArea">
                    <h5
                      className="text-center"
                      style={{ fontWeight: "600", color: "#274187" }}
                    >
                      Dr. Keshav Kumar Sharma
                    </h5>
                    <p className="text-center">New Construction</p>
                  </div>
                </div>
                <div className="thumbnail text-center  my-1 mx-1">
                  <img
                    src={sanjay}
                    alt="hod"
                    id="hodimg"
                    style={{
                      width: "180px",
                      height: "180px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="caption content NameArea">
                    <h5
                      className="text-center"
                      style={{ fontWeight: "600", color: "#274187" }}
                    >
                      Dr. Sanjay Kumar
                    </h5>
                    <p className="text-center">
                      Electrical Maintenance & Service
                    </p>
                  </div>
                </div>
                <div className="thumbnail text-center  my-1 mx-1">
                  <img
                    src={somenath}
                    alt="hod"
                    id="hodimg"
                    style={{
                      width: "180px",
                      height: "180px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="caption content NameArea">
                    <h5
                      className="text-center"
                      style={{ fontWeight: "600", color: "#274187" }}
                    >
                      Dr. Somenath Mondal
                    </h5>
                    <p className="text-center">Staff</p>
                  </div>
                </div>
              </div>
              <div>
                <NoticeDeanOffice dean="Planning and Development" />
              </div>
            </div>
          </div>
        </BoxWrapper>
      </div>
    </>
  );
};

export default DeanPDOffice;

