import React from "react";
import { useTranslation } from "react-i18next";
import Heading from "../../../Components/Heading";
import ContainerWrapper from "../../../Components/UI/Container/style";

export default function AlumniStudentDirectory() {
  const { t } = useTranslation();

  return (
    <div className="wrapper" style={{ margin: "5rem 0rem" }}>
      <Heading
  content={t("Alumni Student Directory")}
  style={{
    fontSize: "1.5em",
    marginBottom: "20px",
    marginTop: "13px",
    fontWeight: "500",
    background: "#274187",
    color: "#fff",
    padding: "1% 1% 1% 3%",
  }}
/>
<ContainerWrapper>
<a
        style={{ paddingRight: "10px" }}
        href="https://syed-ausaf-hasib.github.io/Alumni/index.html"
        rel="noreferrer"
        target={"_blank"}
    >
        {t("Alumni Student Directory 2025")}
    </a>
</ContainerWrapper>

    
 </div>
  );
}
