import React from "react";
import { useTranslation } from "react-i18next";
import Container from "../../../Components/UI/Container/index";
import Heading from "../../../Components/Heading/index";
import { SectionWrapper } from "../../../assets/css/Custom/AcademicStyle";
import { Icon } from "react-icons-kit";
import { Table } from "react-bootstrap";
import { cloudDownload } from "react-icons-kit/fa/cloudDownload";
function Publication({ fontsize }) {
  const { t } = useTranslation();
  return (
    <Container width="1250px">
      <br />
      <Heading
        content={t("cells_top_subtitle_public")}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <div>
        <Table
          striped
          bordered
          hover
          responsive="md"
          style={{ margin: "50px auto" }}
        >
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Name</th>
              <th>Position</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1.</td>
              <td>Prof. Ashok Kumar, MME</td>
              <td>Chairman</td>
            </tr>
            <tr>
              <td>2.</td>
              <td>Dr. Ajay Kumar, ECE</td>
              <td>Departmental Co-Ordinator</td>
            </tr>
            <tr>
              <td>3.</td>
              <td>Dr. (Mrs.) Shubham Tripathi, PIE</td>
              <td>Departmental Co-Ordinator</td>
            </tr>
            <tr>
              <td>4.</td>
              <td>Dr. Somenath Mandal, DCE</td>
              <td>Departmental Co-Ordinator</td>
            </tr>
            <tr>
              <td>5.</td>
              <td>Dr. (Mrs.) Swati Sudha, HSSM</td>
              <td>Departmental Co-Ordinator & Convener</td>
            </tr>
            <tr>
              <td>6.</td>
              <td>Dr. Prabhat Kumar, DCH</td>
              <td>Departmental Co-Ordinator</td>
            </tr>
            <tr>
              <td>7.</td>
              <td>Dr. Ravi Bhushan, DEE</td>
              <td>Departmental Co-Ordinator</td>
            </tr>
            <tr>
              <td>8.</td>
              <td>Dr. Shailesh Jha, DME</td>
              <td>Departmental Co-Ordinator</td>
            </tr>
            <tr>
              <td>9.</td>
              <td>Dr. Binay Kumar, MME</td>
              <td>Departmental Co-Ordinator</td>
            </tr>
            <tr>
              <td>10.</td>
              <td>Dr. Sourav Das, DMA</td>
              <td>Departmental Co-Ordinator</td>
            </tr>
            <tr>
              <td>11.</td>
              <td>Dr. (Mrs.) Gopa Bhaumik, CSE</td>
              <td>Departmental Co-Ordinator</td>
            </tr>
            <tr>
              <td>12.</td>
              <td>Sri. Sunil Kumar Bhagat, Assistant Registrar (Estt.)</td>
              <td>Member</td>
            </tr>
            <tr>
              <td>13.</td>
              <td>Sri. Saikat Mridha, Senior Superintendent, F&A</td>
              <td>Member</td>
            </tr>
            <tr>
              <td>14.</td>
              <td>Sri. Shashikant Behera, Technician, MME</td>
              <td>Member</td>
            </tr>
            <tr>
              <td>15.</td>
              <td>Sri. Navanit Ranjan Sahu, Junior Assistant, Estt.</td>
              <td>Member</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <br />
      <Heading
        content={t("cells_top_subtitle")}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <SectionWrapper>
        <a href="https://nitjsr.ac.in/backend/uploads/rti/new/Office%20Order%20No%20280-2024,%20The%20Publication%20cell%20committee%20is%20constituted%20to%20prepare%20the%20Annual%20Report%20for%20the%20year%202023-24..pdf">
          <p style={{ fontSize: fontsize }} className="pdfLink">
            <Icon icon={cloudDownload} size={18} />
            &nbsp;&nbsp; {t("publication-Cell_para_li1")}
          </p>
        </a>
      </SectionWrapper>
      <br />
      <Heading
        content={t("publication-Cell_annual")}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      <SectionWrapper>
        <a href="https://nitjsr.ac.in/backend/uploads/uploads_2023/Publication_Cell/Annual%20Report%20ENG%202023-24.pdf">
          <p style={{ fontSize: fontsize }} className="pdfLink">
            <Icon icon={cloudDownload} size={18} />
            &nbsp;&nbsp; {t("publication-Cell_annual_2023-24E")}
          </p>
        </a>
        <a href="https://nitjsr.ac.in/backend/uploads/uploads_2023/Publication_Cell/Annual%20Report%20HINDI%202023-24.pdf">
          <p style={{ fontSize: fontsize }} className="pdfLink">
            <Icon icon={cloudDownload} size={18} />
            &nbsp;&nbsp; {t("publication-Cell_annual_2023-24H")}
          </p>
        </a>
        <a href="https://nitjsr.ac.in/backend/uploads/uploads_2023/Publication_Cell/ANNUAL REPORT 21-22 NITJSR  ENGLISH.pdf">
          <p style={{ fontSize: fontsize }} className="pdfLink">
            <Icon icon={cloudDownload} size={18} />
            &nbsp;&nbsp; {t("publication-Cell_annual_para1")}
          </p>
        </a>
        <a href="https://nitjsr.ac.in/backend/uploads/uploads_2023/Publication_Cell/hindi-annual report 2021-22.pdf">
          <p style={{ fontSize: fontsize }} className="pdfLink">
            <Icon icon={cloudDownload} size={18} />
            &nbsp;&nbsp; {t("publication-Cell_annual_para3")}
          </p>
        </a>

        <a href="https://nitjsr.ac.in/backend/uploads/uploads_2023/Publication_Cell/English Annual Report 2020-21-min.pdf">
          <p style={{ fontSize: fontsize }} className="pdfLink">
            <Icon icon={cloudDownload} size={18} />
            &nbsp;&nbsp; {t("publication-Cell_annual_para2")}
          </p>
        </a>
        <a href="https://nitjsr.ac.in/backend/uploads/uploads_2023/Publication_Cell/HINDI Annual Report 2020-21-min.pdf">
          <p style={{ fontSize: fontsize }} className="pdfLink">
            <Icon icon={cloudDownload} size={18} />
            &nbsp;&nbsp; {t("publication-Cell_annual_para4")}
          </p>
        </a>
        <a href="https://nitjsr.ac.in/backend/uploads/uploads_2023/Publication_Cell/1Hindi annual report 2019-20 (1).pdf">
          <p style={{ fontSize: fontsize }} className="pdfLink">
            <Icon icon={cloudDownload} size={18} />
            &nbsp;&nbsp; {t("publication-Cell_annual_para5")}
          </p>
        </a>
      </SectionWrapper>

      <br />
      <Heading
        content={t("Newsletter1")}
        style={{
          fontSize: "1.5em",
          marginBottom: "20px",
          marginTop: "13px",
          fontWeight: "500",
          background: "#274187",
          color: "#fff",
          padding: "1% 1% 1% 3%",
        }}
      />
      {/* <SectionWrapper>
        <p
          className="pdflink"
          style={{ textAlign: "center", fontSize: fontsize }}
        >
          {t("default_para")}
        </p>
      </SectionWrapper> */}
      <SectionWrapper>
        <a href="http://archive.nitjsr.ac.in/publications/files/Newsletter%20Vol-1%20NITJSR_.pdf">
          <p className="pdfLink">
            <Icon icon={cloudDownload} size={18} />
            &nbsp;&nbsp;{t("Newsletter2")}
          </p>
        </a>
        <a href="http://archive.nitjsr.ac.in/publications/files/Newsletter%20Vol-1_Issued-II%20(NITJSR).pdf">
          <p className="pdfLink">
            <Icon icon={cloudDownload} size={18} />
            &nbsp;&nbsp;{t("Newsletter3")}
          </p>
        </a>
        <a href="http://archive.nitjsr.ac.in/publications/files/Newsletter%20Vol-3.pdf">
          <p className="pdfLink">
            <Icon icon={cloudDownload} size={18} />
            &nbsp;&nbsp;{t("Newsletter4")}
          </p>
        </a>
        <a href="http://archive.nitjsr.ac.in/uploads/NEWSLETTER_VOL-IV.pdf">
          <p className="pdfLink">
            <Icon icon={cloudDownload} size={18} />
            &nbsp;&nbsp;{t("Newsletter5")}
          </p>
        </a>
      </SectionWrapper>
    </Container>
  );
}

export default Publication;
