// People section
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import avatar from "../../../assets/images/default.jpg";
import { getFileURL } from "../../../functions/utils";
import { Image } from "antd";
function DeanList({ people, error, showProfile }) {
  const [hover, setHover] = useState(null);
  if (people) {
    console.log("people ", people);
  }
  const deanOffices = ["Academic", "FW",  "RNC", "IR", "SW","PND"];
  return (
    <div>
      <Container>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "2rem",
          }}
        >
          {people &&
            people.length > 0 &&
            people.map((value, index) => {
              if (value) {
                return (
                  <Card
                    key={index}
                    onMouseEnter={() => setHover(index)}
                    onMouseLeave={() => setHover(null)}
                    style={{
                      borderRadius: "5%",
                      cursor: showProfile ? "pointer" : null,
                      pointerEvents: showProfile ? null : "none",
                      backgroundColor: hover === index ? "#E1EEF2" : "",
                      width: 240,
                      height: "max-content",
                      margin: "1rem",
                      padding: "1rem",
                    }}
                  >
                    <center>
                      <Image
                        src={getFileURL(value.image)}
                        fallback={avatar}
                        preview={false}
                        style={{
                          maxHeight: "200px",
                          width: "200px",
                          height: "200px",
                        }}
                      />
                    </center>
                    <Card.Body>
                      <Card.Text>
                        <Link
                          to={"/people/profile/" + value.id}
                          className="text-primary"
                        >
                          <center>
                            <b>{value.name} </b>
                          </center>
                        </Link>
                        <p className="text-center">
                          <Link
                            to={
                              "/Administration/DeanOffice/" + deanOffices[index]
                            }
                          >
                            {" "}
                            {value.committee}
                          </Link>

                          <br />
                          <a
                            href={"mailto:" + value.email}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {value.email}
                          </a>
                        </p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                );
              } else return null;
            })}
          <h4 style={{ marginTop: "3rem", marginBottom: "12rem" }}>{error}</h4>
        </Row>
      </Container>
    </div>
  );
}

// HumanityBlock style props
DeanList.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// List default style
DeanList.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
  },
  // HumanityBlock col default style
  col: {
    width: ["100%", "50%", "50%"],
    pl: "15px",
    pr: "15px",
    mb: "30px",
  },
  colOne: {
    width: ["100%", "30%", "35%", "30%"],
    mt: [0, "13px", "0"],
    mb: ["30px", 0],
    pl: ["15px", 0],
    pr: ["15px", "15px", 0],
  },
  // Footer col two style
  colTwo: {
    width: ["100%", "70%", "65%", "70%"],
    flexBox: true,
    flexWrap: "wrap",
  },
};

export default DeanList;
