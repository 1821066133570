//profile img
import adarshKashyap from "../../../assets/images/achievements/adarshKashyap.png";
import alokThakur from "../../../assets/images/achievements/Alok Thakur.jpg";
import MuskanGupta from "../../../assets/images/achievements/Muskan Gupta.jpg";
import rajdeepKumar from "../../../assets/images/achievements/rajdeep kumar.jpg";
import shreyaKumari from "../../../assets/images/achievements/Shreya Kumari.png";
import srirajivRanjan from "../../../assets/images/achievements/Srirajiv Ranjan.jpg";
import suvrodeep from "../../../assets/images/achievements/Suvrodeep.jpg";
import ujjwalKumar from "../../../assets/images/achievements/UJJWAL KUMAR.jpg";
import sutej from "../../../assets/images/achievements/Sutej.jpg";
import jasmine from "../../../assets/images/achievements/Jasmine Kaur.jpg";
import aditya from "../../../assets/images/achievements/aditya kumar.jpg";
import saurabh from "../../../assets/images/achievements/saurabh gupta.jpg";
import shashank from "../../../assets/images/achievements/shashank.jpeg";

// logos
// import invalid_  ~image from "../../../assets/images/invalid_image.png";
import atlassianLogo from "../../../assets/images/companylogo/Atlassian_logo_PNG1.png";
import arcesium from "../../../assets/images/companylogo/arcesium.png";
import chargebee from "../../../assets/images/companylogo/chargebee.jpg";
import essar from "../../../assets/images/companylogo/essar.jpg";
import hexaware from "../../../assets/images/companylogo/hexaware.png";
import microsoft from "../../../assets/images/companylogo/MICROSOFT.png";
import qualcom from "../../../assets/images/companylogo/qualcom.png";
import zs from "../../../assets/images/companylogo/ZS_Associates.png";
import google from "../../../assets/images/companylogo/google_logo.png";
import salesforce from "../../../assets/images/companylogo/salesforce-logo.png";
import intuit from "../../../assets/images/companylogo/Intuit-logo.png";
import amazon from "../../../assets/images/companylogo/amazon.png";
import signaltron from "../../../assets/images/companylogo/signaltron.png";

export const data = [
  {
    title: "Shashank Karan",
    desc: signaltron,
    imageLink: shashank,
    fontsize: "2rem",
    link: "#",
    brochure: "#",
  },
  {
    title: "Adarsh Kashyap",
    desc: atlassianLogo,
    imageLink: adarshKashyap,
    fontsize: "2rem",
    link: "#",
    brochure: "#",
  },
  {
    title: "Sutej Sharma",
    desc: google,
    imageLink: sutej,
    fontsize: "2rem",
    link: "#",
    brochure: "#",
  },
  {
    title: "Jasmine Kaur",
    desc: salesforce,
    imageLink: jasmine,
    fontsize: "2rem",
    link: "#",
    brochure: "#",
  },
  {
    title: "Muskan Gupta",
    desc: arcesium,
    imageLink: MuskanGupta,
    fontsize: "2rem",
    link: "#",
    brochure: "#",
  },
  {
    title: "Rajdeep Kumar",
    desc: essar,
    imageLink: rajdeepKumar,
    fontsize: "2rem",
    link: "#",
    brochure: "#",
  },
  {
    title: "Shreya Kumari",
    desc: qualcom,
    imageLink: shreyaKumari,
    fontsize: "2rem",
    link: "#",
    brochure: "#",
  },

  {
    title: "Aditya Kumar",
    desc: intuit,
    imageLink: aditya,
    fontsize: "2rem",
    link: "#",
    brochure: "#",
  },
  {
    title: "Srirajiv Ranjan",
    desc: microsoft,
    imageLink: srirajivRanjan,
    fontsize: "2rem",
    link: "#",
    brochure: "#",
  },
  {
    title: "Suvrodeep",
    desc: chargebee,
    imageLink: suvrodeep,
    fontsize: "2rem",
    link: "#",
    brochure: "#",
  },
  {
    title: "Saurabh Gupta",
    desc: amazon,
    imageLink: saurabh,
    fontsize: "2rem",
    link: "#",
    brochure: "#",
  },

  {
    title: "Ujjwal Kumar",
    desc: zs,
    imageLink: ujjwalKumar,
    fontsize: "2rem",
    link: "#",
    brochure: "#",
  },
];
